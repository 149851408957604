import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Settings`}</h1>
    <p>{`Setting window allows for you to change specific setup and default values of Response Time Track.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f0f550aa8027387d07d937505ca2867f/ec605/setting_nav.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "203.33333333333331%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAApABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIEAQP/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAwEC/9oADAMBAAIQAxAAAAHfWiu5421qOqnSVA8c5uQP/8QAHhAAAQMEAwAAAAAAAAAAAAAAAAISEwEDEDIRFCD/2gAIAQEAAQUCgSQJOvTPPtw4cK1t7H//xAAYEQADAQEAAAAAAAAAAAAAAAACE1EAIP/aAAgBAwEBPwFYzKGc/wD/xAAYEQACAwAAAAAAAAAAAAAAAAACEwAgUf/aAAgBAgEBPwFhbGFX/8QAGBAAAgMAAAAAAAAAAAAAAAAAEEEAATD/2gAIAQEABj8CDi0s/wD/xAAfEAACAQMFAQAAAAAAAAAAAAAAARFBcZEQITFRoWH/2gAIAQEAAT8hSKvw+jwhVWptIldkDOBXE57RDnh5IILCwsPNq//aAAwDAQACAAMAAAAQxA4Ac8//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EFl//8QAFhEAAwAAAAAAAAAAAAAAAAAAACBh/9oACAECAQE/EBXX/8QAIhABAAIBAgYDAAAAAAAAAAAAAQARITFREEFhcZGhgcHw/9oACAEBAAE/ELQF7q+oYv2fEeKxbgRx4juWvmSrqmyxW3Mg6qejiUbNRKSV2ktt6ltvU588ddZqC/eA3eeFaPZ4f//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Settings Navigation",
            "title": "Settings Navigation",
            "src": "/static/f0f550aa8027387d07d937505ca2867f/09b79/setting_nav.jpg",
            "srcSet": ["/static/f0f550aa8027387d07d937505ca2867f/59e87/setting_nav.jpg 60w", "/static/f0f550aa8027387d07d937505ca2867f/e439a/setting_nav.jpg 120w", "/static/f0f550aa8027387d07d937505ca2867f/09b79/setting_nav.jpg 240w", "/static/f0f550aa8027387d07d937505ca2867f/158ba/setting_nav.jpg 360w", "/static/f0f550aa8027387d07d937505ca2867f/7cc5e/setting_nav.jpg 480w", "/static/f0f550aa8027387d07d937505ca2867f/ec605/setting_nav.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Setting Items`}</h2>
    <h3>{`Location Tracking`}</h3>
    <p>{`Location tracking is used to calculate the mileage traveled during a rescue time.
This feature only displays for premium Response Time Trackers.`}</p>
    <h3>{`History Information Layout`}</h3>
    <p>{`History information layout determines the layout on first load into history window. The options are `}<inlineCode parentName="p">{`card`}</inlineCode>{` and `}<inlineCode parentName="p">{`grid`}</inlineCode>{`.`}</p>
    <h3>{`CSV File Name`}</h3>
    <p>{`When exporting csv file, the default name of CSV will have whatever text in settings added with the current date and time.`}</p>
    <h3>{`Filter Record Duration displays`}</h3>
    <p>{`Filter record duration displays is the default amount of days from today to filter rescue times in the history window.`}</p>
    <h3>{`Purchase subscription`}</h3>
    <p>{`For those who are not a premium Response Time Tracker, there is a place to purchase a subscription for no ads, cloud storage, and location tracking.`}</p>
    <h3>{`Enable Notes Entry`}</h3>
    <p>{`Enable notes entry will display a text box to add a note to rescue time at the end of tracking rescue time in time window.`}</p>
    <h3>{`Trucks Info`}</h3>
    <p>{`Window for setting up truck information for the app.`}</p>
    <h4>{`Enable Truck Entry`}</h4>
    <p>{`Enable truck entry to display truck selection towards the end of tracking rescue time in time window.`}</p>
    <h4>{`Truck Default`}</h4>
    <p>{`Select the default truck to be selected during the truck entry.`}</p>
    <h4>{`Truck Information`}</h4>
    <p>{`Setup all of the trucks that can be used for a selection.
Tap on the blue button to add a truck to the truck selection.
Hold and swipe left on record to display edit and delete options of truck selection.`}</p>
    <h3>{`Delete Account`}</h3>
    <p>{`Delete account will only display for those who are login. Delete account will delete account.`}</p>
    <h3>{`Promote this App`}</h3>
    <p>{`Allows for different ways to promote our app to others`}</p>
    <h4>{`Send Feedback`}</h4>
    <p>{`Allows for feedback to be sent to us. Feel free to leave a comment, feedback, or suggestions about the app.`}</p>
    <h4>{`Leave review on App Store/Google Play Store`}</h4>
    <p>{`Depending on the phone, it will open Apple App Store or Google Play Console of the app.`}</p>
    <h4>{`Share our website`}</h4>
    <p>{`Opens up sharing options of our website.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      