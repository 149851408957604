import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/owenbean/ems/ems_web/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Time Window`}</h1>
    <p>{`The time window is the tool that collects the time at which an action has happened.
At the end, the time window will ask for user to enter mileage traveled.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1072a71d01e1315480c6a4d06f899e85/7cc5e/time_screen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAwUC/8QAGAEBAAMBAAAAAAAAAAAAAAAABAABAgP/2gAMAwEAAhADEAAAAdBarhoNwUCsuX0IlBJjp//EAB0QAAIBBAMAAAAAAAAAAAAAAAECAwAEEiEQERP/2gAIAQEAAQUCmmxIncFGzV9SVbg+ckQelttgdDn/xAAZEQADAQEBAAAAAAAAAAAAAAABAhEAAxD/2gAIAQMBAT8Bbo1ytRceak3z/8QAGREAAwEBAQAAAAAAAAAAAAAAAQIRAAMQ/9oACAECAQE/AV5rMwhmHRgJ5//EAB8QAAIBAgcAAAAAAAAAAAAAAAABEQJREBIgITGBkf/aAAgBAQAGPwLLScyShzfDsszerwhaP//EAB0QAQACAgIDAAAAAAAAAAAAAAEAERBBITFRYYH/2gAIAQEAAT8hXanbOeUeEgBuhYRTRX2XSNEc/fsgbJAAFBgx/9oADAMBAAIAAwAAABDLw/z/xAAYEQEAAwEAAAAAAAAAAAAAAAABABARIf/aAAgBAwEBPxBLdyMKJrh2v//EABgRAQADAQAAAAAAAAAAAAAAAAEAEBEh/9oACAECAQE/EAEzYahmAPK//8QAIBABAAICAQQDAAAAAAAAAAAAAQARMUEhEFFhgXGRwf/aAAgBAQABPxBZRuy6exCCW4H5MN+Q5HtAEWivzzGnKvaKsAk+iOlwlVLv5IixHkHL7gYBUBrpt0//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Time Screen",
            "title": "Time Screen",
            "src": "/static/1072a71d01e1315480c6a4d06f899e85/09b79/time_screen.jpg",
            "srcSet": ["/static/1072a71d01e1315480c6a4d06f899e85/59e87/time_screen.jpg 60w", "/static/1072a71d01e1315480c6a4d06f899e85/e439a/time_screen.jpg 120w", "/static/1072a71d01e1315480c6a4d06f899e85/09b79/time_screen.jpg 240w", "/static/1072a71d01e1315480c6a4d06f899e85/158ba/time_screen.jpg 360w", "/static/1072a71d01e1315480c6a4d06f899e85/7cc5e/time_screen.jpg 480w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bc58d8ad6a41b2332b15aaf739dce6ef/7cc5e/time_nav.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHWsJAX/8QAFhAAAwAAAAAAAAAAAAAAAAAAARAx/9oACAEBAAEFAhF//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BJ//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAYEAADAQEAAAAAAAAAAAAAAAAAARExQf/aAAgBAQABPyFJJ1D0/9oADAMBAAIAAwAAABALz//EABURAQEAAAAAAAAAAAAAAAAAABAx/9oACAEDAQE/EIP/xAAVEQEBAAAAAAAAAAAAAAAAAAAQMf/aAAgBAgEBPxCj/8QAGBABAQEBAQAAAAAAAAAAAAAAAQARIVH/2gAIAQEAAT8QBBuD7NLq/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Time Navigation",
            "title": "Time Navigation",
            "src": "/static/bc58d8ad6a41b2332b15aaf739dce6ef/09b79/time_nav.jpg",
            "srcSet": ["/static/bc58d8ad6a41b2332b15aaf739dce6ef/59e87/time_nav.jpg 60w", "/static/bc58d8ad6a41b2332b15aaf739dce6ef/e439a/time_nav.jpg 120w", "/static/bc58d8ad6a41b2332b15aaf739dce6ef/09b79/time_nav.jpg 240w", "/static/bc58d8ad6a41b2332b15aaf739dce6ef/158ba/time_nav.jpg 360w", "/static/bc58d8ad6a41b2332b15aaf739dce6ef/7cc5e/time_nav.jpg 480w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Time Actions`}</h2>
    <p>{`Each action will have a button in the middle of the screen to push when switching to a different actions.
Below the button is a `}<inlineCode parentName="p">{`Go Back`}</inlineCode>{` text that is clickable.
When clicked on, the action will go backwards and will override previous time when clicking on the button to go forward.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Call`}</inlineCode></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Push when the call has been retrieved.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Enroute`}</inlineCode></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Push after call ended and starting the drive to the scene.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Scene`}</inlineCode></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Push after arriving onto the scene.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Transport`}</inlineCode></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Push when leaving the scene.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Destination`}</inlineCode></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Push after arriving to destination.`}</p>
        </blockquote>
      </li>
    </ul>
    <h2>{`Mileage`}</h2>
    <p>{`At the end of the actions is a place to enter the mileage traveled during the rescue.
Click on the white box in the middle of the pop up box to display the keyboard.
Enter the mileage in the white box. After typing out the mileage, hit `}<inlineCode parentName="p">{`Complete`}</inlineCode>{` to save the time record.
If nothing was typed in the box, the mileage will be saved as 0 miles.
If `}<inlineCode parentName="p">{`Destination`}</inlineCode>{` button was accidently clicked, there is a `}<inlineCode parentName="p">{`Back`}</inlineCode>{` text in the mileage display box to go backwards.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2977d53ee87a32e815a2d15166bf68c4/ec605/time_mileage_screen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAejbTN55GwWEaAf/xAAaEAACAgMAAAAAAAAAAAAAAAAAAgETFCBB/9oACAEBAAEFAqFgoUx0O6//xAAYEQADAQEAAAAAAAAAAAAAAAACE1EAEP/aAAgBAwEBPwFYzLGd/8QAGREAAgMBAAAAAAAAAAAAAAAAAlEAEBIT/9oACAECAQE/Adk50J3/AP/EABYQAQEBAAAAAAAAAAAAAAAAAEEAIP/aAAgBAQAGPwJmd//EABwQAAICAgMAAAAAAAAAAAAAAAABEUEgkSFh8P/aAAgBAQABPyGgn0eUHduc0JYsP//aAAwDAQACAAMAAAAQt/AA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxALf//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QGX//xAAfEAEAAgAGAwAAAAAAAAAAAAABABEgITFRYdFxsfD/2gAIAQEAAT8QvINetr2Tm+PEH63UUWHtcHMypZLcH//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Mileage Screen",
            "title": "Mileage Screen",
            "src": "/static/2977d53ee87a32e815a2d15166bf68c4/09b79/time_mileage_screen.jpg",
            "srcSet": ["/static/2977d53ee87a32e815a2d15166bf68c4/59e87/time_mileage_screen.jpg 60w", "/static/2977d53ee87a32e815a2d15166bf68c4/e439a/time_mileage_screen.jpg 120w", "/static/2977d53ee87a32e815a2d15166bf68c4/09b79/time_mileage_screen.jpg 240w", "/static/2977d53ee87a32e815a2d15166bf68c4/158ba/time_mileage_screen.jpg 360w", "/static/2977d53ee87a32e815a2d15166bf68c4/7cc5e/time_mileage_screen.jpg 480w", "/static/2977d53ee87a32e815a2d15166bf68c4/ec605/time_mileage_screen.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Truck`}</h2>
    <p>{`If enabled truck entry within setting windows, a truck entry will display after mileage input.
Tap on the selection box to choose the truck that was used during rescue.
When finished, hit `}<inlineCode parentName="p">{`Complete`}</inlineCode>{` to finish the rescue time.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1361bba6ffe1ea47513b3ca24874bfec/ec605/time_truck_screen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCBf/EABYBAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAB6Oql5yLBoRoB/8QAGRAAAwADAAAAAAAAAAAAAAAAAAITFCAh/9oACAEBAAEFAoKQUx0O7f/EABgRAAMBAQAAAAAAAAAAAAAAAAITUQAQ/9oACAEDAQE/AVjMsZ3/xAAZEQACAwEAAAAAAAAAAAAAAAACUQAQEhP/2gAIAQIBAT8B2TnQnf8A/8QAFhABAQEAAAAAAAAAAAAAAAAAQQAg/9oACAEBAAY/AmZ3/8QAHRAAAgIBBQAAAAAAAAAAAAAAAAERIZEgMUFh8P/aAAgBAQABPyFKcZ0eUHdmbqIfIlo//9oADAMBAAIAAwAAABCEMAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EAt//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxARf//EACAQAQACAAUFAAAAAAAAAAAAAAEAESAhMUHRUWFxsfD/2gAIAQEAAT8QSAZ1KPUq3+PEG4nEQKhUt0F9ogZmD//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Truck Entry Screen",
            "title": "Truck Entry Screen",
            "src": "/static/1361bba6ffe1ea47513b3ca24874bfec/09b79/time_truck_screen.jpg",
            "srcSet": ["/static/1361bba6ffe1ea47513b3ca24874bfec/59e87/time_truck_screen.jpg 60w", "/static/1361bba6ffe1ea47513b3ca24874bfec/e439a/time_truck_screen.jpg 120w", "/static/1361bba6ffe1ea47513b3ca24874bfec/09b79/time_truck_screen.jpg 240w", "/static/1361bba6ffe1ea47513b3ca24874bfec/158ba/time_truck_screen.jpg 360w", "/static/1361bba6ffe1ea47513b3ca24874bfec/7cc5e/time_truck_screen.jpg 480w", "/static/1361bba6ffe1ea47513b3ca24874bfec/ec605/time_truck_screen.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Notes`}</h2>
    <p>{`If enabled notes within setting windows, a note entry will display after the mileage input or truck entry.
Enter a note that you want to add with the rescue time.
When finished, hit `}<inlineCode parentName="p">{`Complete`}</inlineCode>{` to finish the rescue time.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f3eb450f09bcf6f5785dced5c2d22dbe/ec605/notes_screen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAEEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAB6N1W88baKI0A/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAITAREUIEH/2gAIAQEAAQUCgUgUxlO3Kaf/xAAYEQACAwAAAAAAAAAAAAAAAAACEwAQUf/aAAgBAwEBPwFYxQZf/8QAGREAAQUAAAAAAAAAAAAAAAAAAgAQEhNR/9oACAECAQE/AZkrC1//xAAYEAACAwAAAAAAAAAAAAAAAAABECAxQf/aAAgBAQAGPwLVZn//xAAaEAADAAMBAAAAAAAAAAAAAAAAAREQYYGh/9oACAEBAAE/IUttXuG1+CaNmxWUx//aAAwDAQACAAMAAAAQDy88/8QAFREBAQAAAAAAAAAAAAAAAAAAIGH/2gAIAQMBAT8QiB//xAAVEQEBAAAAAAAAAAAAAAAAAAAgYf/aAAgBAgEBPxC43//EAB4QAQACAQQDAAAAAAAAAAAAAAEAEUEhUaHRYZHx/9oACAEBAAE/ENAAZsvifP6QK+Y6lGSreGDFleolFatcSjaU2JQYn//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Notes Entry Screen",
            "title": "Notes Entry Screen",
            "src": "/static/f3eb450f09bcf6f5785dced5c2d22dbe/09b79/notes_screen.jpg",
            "srcSet": ["/static/f3eb450f09bcf6f5785dced5c2d22dbe/59e87/notes_screen.jpg 60w", "/static/f3eb450f09bcf6f5785dced5c2d22dbe/e439a/notes_screen.jpg 120w", "/static/f3eb450f09bcf6f5785dced5c2d22dbe/09b79/notes_screen.jpg 240w", "/static/f3eb450f09bcf6f5785dced5c2d22dbe/158ba/notes_screen.jpg 360w", "/static/f3eb450f09bcf6f5785dced5c2d22dbe/7cc5e/notes_screen.jpg 480w", "/static/f3eb450f09bcf6f5785dced5c2d22dbe/ec605/notes_screen.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      